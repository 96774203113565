<template>
  <div class="home">
    <b-row v-if="true" class="mb-3">
      <b-col cols="12">
         <br/>
        <h4 style="text-align: center; background: #cccccc; color: #fff; padding: 16px 30px; border-radius: 3px 3px 0 0; ">PERFORMANCE</h4>
        <br/>
      </b-col>
      
    </b-row>
    
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Home',
  data: () => ({
  }),
  created(){
    this.setBreadcrumb([
      {
        text: 'Report / Performance',
        active: true
      }
    ])
  },
  methods: {
      ...mapMutations('breadcrumbService', ['setBreadcrumb']),
      navigateTo(route){
        this.$router.push({path: route})
      }
  }
}
</script>
